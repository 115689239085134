import { React, useEffect, useState } from "react";
import './ActionForm.css';
import { extractKeyName, fieldTypeDic, sortArray } from "../../utils/externalFunc";
import { useSelector } from "react-redux";

export default function ContactSection(props){

    const store = useSelector(state => state.reducer)
    const [formErrors, setFormErrors] = useState({})
    const [mainArray, setMainArray] = useState(props.stepInfo.FormFields)

    const updateContactObj = (value, path) => {
        setFormErrors({...formErrors, [path]: ""})
        props.setFormState({...props.formValsLocal.Contact, [path]: value}, "Contact")     
    }

    useEffect(() => {
        setFormErrors(props.errorsObjReady)
    }, [props.errorsObjReady])

    useEffect(() => {
        let arr = sortArray(props.stepInfo.FormFields)
        setMainArray(arr)
    }, [props.stepInfo.FormFields])
      
    return  <>  <div className="ContactSection my-5">
                    <h2 className="font-xl text-center primary_color f-bold">{props.stepInfo.Title}</h2>
                </div>
                <form action="" id="ContactSection_form" className={`ContactSection_form needs-validation ${props.validation === "start" ? "" : "was-validated"}`} noValidate> {/** was-validated */}

                    <div className="row">
                        {
                            mainArray?.map((item, index) => {
                                let formFieldDetails = item.FormFieldDetails ? JSON.parse(item.FormFieldDetails) : false
                                let keyName = extractKeyName(formFieldDetails.object_path)
                                return (formFieldDetails.identifier === "FirstName" || formFieldDetails.identifier === "LastName") ? 
                                <div key={index} className="col-6">
                                    <label htmlFor={formFieldDetails.identifier} className="form-label mb-1">
                                        <span>{item.IsRequired ? "*" : ""}{item.Title}</span>
                                    </label>
                                    <div className="input-group input-group-sm mb-3"> 
                                        <input type={fieldTypeDic[item.FormFieldType]} value={props.formValsLocal?.Contact[keyName] || ""} className={`form-control ${formErrors[keyName] ? "is-invalid" : ""}`} onChange={(e) => updateContactObj(e.target.value, keyName)} id={formFieldDetails.identifier} required={item.IsRequired}/>
                                        <div className="invalid-feedback">{props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}</div>
                                    </div>
                                </div> :
                                <label key={index} htmlFor={formFieldDetails.identifier} className="form-label mb-1">
                                    <span>{item.IsRequired ? "*" : ""}{item.Title}</span>
                                    <div className="input-group input-group-sm mb-3"> 
                                        <input type={fieldTypeDic[item.FormFieldType]} value={props.formValsLocal?.Contact[keyName] ? props.formValsLocal?.Contact[keyName] : ""} className={`form-control ${formErrors[keyName] ? "is-invalid" : ""}`} onChange={(e) => updateContactObj(e.target.value, keyName)} id={formFieldDetails.identifier} required={item.IsRequired}/>
                                        <div className="invalid-feedback">{props.errorsObjReady[keyName] || `יש למלא ${item.Title}`}</div>
                                    </div>
                                </label>
                            })
                        }
                    </div>
                </form></>
}