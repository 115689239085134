import React from "react";

export default function ExcelExport(props) {
    
    return  <div className="d-none">
              <table id="my-table" border="1">
                  <thead>
                      <tr>
                      {
                          props.columns && props.columns.map((item, index) => {
                              let condition = item.field !== "follow" && item.field !== "madeForCompany";
                              if(condition){
                                  return <th key={index}>{item.headerName}</th>
                              }
                          })
                      }
                      </tr>
                  </thead>
                  <tbody>
                      {
                          props.tableRows && props.tableRows.map((item, index) => {
                              return (
                                  <tr key={index}>
                                      {props.columns && props.columns.map((col, i) => {
                                          let condition = col.field in item && col.field !== "madeForCompany";
                                          if(condition){
                                              return <td key={i}>{item[col.field]}</td>          
                                          }                                  
                                      })}
                                  </tr>
                              )  
                          })
                      }
                  </tbody>
              </table>
          </div> 
}